<template>
  <div>
      <vs-row class="mb-2" v-if="!hideFilters">
          <vs-col vs-xs="12" vs-sm="4" vs-lg="2" class="px-0 pr-2 mb-1">
              <InputSelect
                  :model.sync="filters.return_type"
                  input_class="w-full bg-white"
                  label="Return Type"
                  :options="return_type_options"
                  placeholder="Click to Select"
              />
          </vs-col>
          <vs-col vs-xs="12" vs-sm="4" vs-lg="2" class="px-0 pr-2 mb-1">
              <InputSelect
                  :model.sync="filters.year"
                  input_class="w-full bg-white"
                  label="Year"
                  :options="year_options"
                  placeholder="Click to Select"
              />
          </vs-col>
          <vs-col vs-xs="12" vs-sm="1" vs-lg="1" class="px-1 ml-auto">
              <vx-tooltip text="Refresh Data" color="primary" >
                  <vs-button color="primary" type="filled" class="w-full" @click="init()" :disabled="loading">
                      <font-awesome-icon :icon="redoIcon" />
                  </vs-button>
              </vx-tooltip>
          </vs-col>
      </vs-row>

      <div class="vx-col w-full mb-base">
          <vx-card>
              <vs-table :data="results" slot="no-body" hoverFlat noShadow>
                  <template slot="thead">
                      <template v-if="viewMode == 'compact'">
                          <vs-th>Period</vs-th>
                          <vs-th>Filed</vs-th>
                          <vs-th>ARN</vs-th>
                      </template>
                      <template v-else>
                          <vs-th>Period</vs-th>
                          <vs-th>Filed</vs-th>
                          <vs-th>Filing Date</vs-th>
                          <vs-th>ARN</vs-th>
                          <vs-th>Last Updated</vs-th>
                      </template>
                  </template>
                  <template>
                      <template v-for="(item, index) in results">
                          <vs-tr :key="`compact-${item.id}`" v-if="viewMode == 'compact'">
                              <vs-td>
                                  {{ item.month }} / {{ item.year }}
                              </vs-td>
                              <vs-td>
                                  <font-awesome-icon :icon="checkIcon" class="text-success" v-if="item.filed" />
                                  <font-awesome-icon :icon="crossIcon" class="text-danger" v-else />
                              </vs-td>
                              <vs-td>
                                  {{ item.arn }}
                              </vs-td>
                          </vs-tr>
                          <vs-tr :key="item.id" v-else>
                              <vs-td>
                                  {{ item.month }} / {{ item.year }}
                              </vs-td>
                              <vs-td>
                                  <font-awesome-icon :icon="checkIcon" class="text-success" v-if="item.filed" />
                                  <font-awesome-icon :icon="crossIcon" class="text-danger" v-else />
                              </vs-td>
                              <vs-td>
                                <FormatText :text="item.date_of_filing" format="date" />
                              </vs-td>
                              <vs-td>
                                {{ item.arn }}
                              </vs-td>
                              <vs-td>
                                <FormatText :text="item.modified_on" format="date" />
                              </vs-td>
                          </vs-tr>
                      </template>
                  </template>
              </vs-table>
          </vx-card>
      </div>
  </div>
</template>


<script>
import { faCheckCircle, faPlus, faRedo, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

export default {
  props: {
      entity: {
        required: true,
        type: Object
      },
      embedded_mode: {
          required: false,
          default: false
      },
      embedded_filters: {
          required: false,
          default: () => {}
      },
      disablePagination: {
          type: Boolean,
          required: false,
          default: false
      },
      global_mode: {
          required: false,
          type: Boolean,
          default: false
      },
      hideFilters: {
          type: Boolean,
          required: false,
          default: false
      },
      viewMode: {
        type: String,
        default: 'normal' // normal | compact
      }
  },
  data() {
      return  {
          results: [],

          loading: false,

          filters: {
              return_type: this.$route.query.return_type || 'r3b',
              year: this.$route.query.year || new Date().getFullYear(),
          },

          return_type_options: [
            {label: 'GSTR-3B', value: 'r3b'},
            {label: 'GSTR-1', value: 'r1'},
            {label: 'GSTR-9', value: 'r9'},
            {label: 'GSTR-9A', value: 'r9a'},
            {label: 'GSTR-9B', value: 'r9b'},
            {label: 'GSTR-9C', value: 'r9c'},
          ]
      }
  },
  mounted() {
      this.init()
  },
  computed: {
      redoIcon: () => faRedo,
      checkIcon: () => faCheckCircle,
      crossIcon: () => faTimesCircle,
      plusIcon: () => faPlus,
      year_options() {
        let years = []
        for (let i = 2016; i <= new Date().getFullYear(); i++) years.push({label: i.toString(), value: i})

        return years
      }
  },
  watch: {
      filters: {
          deep: true,
          handler(newV) {

              if (this.timer) clearTimeout(this.timer);

              this.timer = setTimeout(() => {
                  this.$router.push({ name: this.$route.name, query: {...newV, ...this.embedded_query_params} });
              }, 800);
          }
      },
      "$route.query"(newV) {
          this.load_data(newV);
      },
  },
  methods: {
      init(){
          this.load_data(this.filters)
      },
      load_data(filters=null) {
          this.loading = true;
          this.$vs.loading();

          let url = this.sprintf(
            this.$store.state.apiendpoints.data_entity_registrations_filing_history,
            this.$route.params.registration_id,
          )

          let payload = filters

          if (!payload) payload = {};

          if (this.embedded_mode && this.embedded_filters) {
              payload = { ...payload, ...this.embedded_filters };
          }

          this.api_errors = {}

          this.$api(this, 'GET', url, payload).then(
              (res) => {
                  this.results = res
                  this.loading = false;
                  this.$vs.loading.close();
              },
              (err) => {
                  this.loading = false;
                  this.$vs.loading.close();
                  if (err.error) {
                      this.api_errors = err.error

                      this.$vs.notify({
                          time: 2500,
                          title: 'Error',
                          text: "Please fix the errors and try again!",
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'danger'
                      })
                  } else {
                      this.$vs.notify({
                          time: 2500,
                          title: 'Error',
                          text: "Unable to load filing history. Please try again later.",
                          iconPack: 'feather',
                          icon: 'icon-alert-circle',
                          color: 'danger'
                      })
                  }
              }
          )
      },
  }
}

</script>
